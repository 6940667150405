footer.content-info{
    @media(max-width: $screen-xs-max){
        margin-top: 0 !important;
        .widget{
            text-align: center !important;
            min-height: 10px !important;
            ul.menu{
                padding: 0 10px !important;
                font-size: 16px !important;
                text-align: center !important;
                li{
                    padding: 2px 0 !important;
                }
            }
        }
    }
    @media(max-width: $screen-sm-max){
        margin-top: 60px;
        .widget{
            min-height: auto !important;
            margin-bottom: 20px;
        }
        .bottom-line{
            .widget{
                margin-bottom: 0;
                min-height: auto;
            }
        }
        .logo-line{
            //padding: 60px;
        }
    }
    @media(max-width: $screen-xs-max){
        .logo-line{
            padding: 0 10px;
        }
    }
    background: #ddd;
    .logo-line{
        .icon-logo{
            @media(max-width: $screen-xs-max){
                font-size: 160px;
            }
            font-size: 200px;
            line-height: 0.2;
        }
    }
    .widget{
        text-align: left;
        border-left: 1px solid #ccc;
        min-height: 240px;
        &:first-child{
            border-left: 0;
        }
        h3{
            color: #666;
            &:first-child{
                font-weight: 600;
                font-size: 20px;
                text-transform: uppercase;
                padding: 0;
                margin: 0;
            }
        }
        ul.menu{
            padding: 0 10px;
            font-size: 18px;
            text-align: left;
            list-style-type: none;
            li{
                padding: 6px 0 ;
                a{
                    color: #333;
                    &:hover{
                        color: #333;
                        opacity: 0.8;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .bottom-line{
        @media(max-width: $screen-xs-max){
            margin-top: 20px;
        }
        margin-top: 60px;
        background: #aaa;
        color: $white;
        font-size: 16px;
        font-weight: 400;
        padding: 16px;
        .widget{
            min-height: auto;
            text-align: center;
        }
    }
}

.next-block{
    width: 60px;
    height: 60px;
    color: $white;
    background: $brand-primary;
    border-radius: 100px;
    position: fixed;
    display: block;
    bottom: 40px;
    right: 40px;
    box-shadow: 0 4px rgba(0,0,0,0.1);
    z-index: 100;
    text-align: center;
    transition: all 0.3s;
    &:hover{
        transition: all 0.3s;
        background: #1f6d9b;
    }
    span{
        line-height: 60px;
        position: relative;
    }
}