.top-navigation-bar{
    @media(max-width: $screen-sm-max){
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000000;
        box-shadow: 0 4px rgba(0,0,0,0.1);
    }
    background: $brand-primary;
    display: table;
    width: 100%;
    .brand{
        float: left;
        display: block;
        width: 200px;
        margin-left: 20px;
        height: 80px;
        color: $white;
        position: relative;
        .icon-logo{
            font-size: 160px;
            line-height: 1;
            position: absolute;
            top: -50%;
            &:before{
                margin: 0 !important;
            }
        }
    }
    .language-switcher{
        padding: 28px;
        font-size: 18px;
        font-weight: 600;
        float: left;
        text-transform: uppercase;
        color: #98b6c8;
        .active{
            color: $white;
        }
        span{
            a{
                color: $lighter-blue;
                text-decoration: none;
                &:hover{
                    text-decoration: none;
                    opacity: 0.8;
                }
            }
        }
    }
    nav{
        @media(max-width: $screen-sm-max){
            float: none;
            position: fixed;
            display: none;
            top: 80px;
            z-index: 1000;
            background: $brand-primary;
            bottom: 0;
            left: 0;
            right: 0;
            &.active{
                display: block;
            }
            .menu-item{
                &:hover{
                    background: none !important;
                }
            }
            .nav{
                width: 100%;
                position: relative;
                top: 0;
                left: 0;
                .menu-item{
                    text-align: center;
                    clear: both;
                    float: none;
                    width: 100%;
                    a{
                        padding: 16px 0 !important;
                    }
                    .sub-menu{
                        position: relative !important;
                        left: 0 !important;
                        top: 0 !important;
                        padding: 0;
                        height: auto !important;
                        width: 100%;
                        background: none !important;
                        border-top: 1px dashed $lighter-blue;
                        border-bottom: 1px dashed $lighter-blue;
                        .menu-item{
                            position: relative;
                            float: none;
                            a{
                                padding: 16px 0;
                                color: $lighter-blue;
                            }
                        }
                    }
                }
            }
            .search-box{
                @media(max-width: $screen-sm-max){
                    float: none !important;
                    display: table;
                    margin: 0 auto !important;
                    width: inherit;
                    ul.sub-menu{
                        border-top: 0 !important;
                        border-bottom: 0 !important;
                        margin-top: 20px;
                        display: block !important;
                        .search-field{
                            border-left: 0 !important;
                            background: rgba(255,255,255,0.2);
                        }
                        .search-submit{
                            border-left: 0 !important;
                            background: rgba(255,255,255,0.1);
                            padding: 5px 12px 4px 12px !important;
                            border-radius: 0;
                            margin: 0 !important;
                        }
                    }
                }
                @media(max-width: $screen-xs-max){
                    ul.sub-menu{
                        width: 70vw !important;
                    }
                }
                a.search-ikon{
                    display: none !important;
                }
            }
        }
        float: right;
        .nav{
            padding: 0;
            margin: 0;
            float: left;
            .menu-item{
                float: left;
                position: inherit !important;
                z-index: 1;
                @media(min-width: $screen-md-min){
                    transition: all 0.3s;
                    transition-delay: 0.3s;
                }
                a{
                    font-size: 18px;
                    padding: 28px 30px;
                    display: block;
                    color: $white;
                    text-transform: uppercase;
                    &:hover,&:focus,&:active{
                        background: none !important;
                    }
                    span.glyphicon{
                        font-size: 12px;
                        line-height: 100%;
                        margin-left: 4px;
                        &.glyphicon-search{
                            font-size: 18px;
                        }
                    }
                }
                &.active,&:focus,&:active{
                    background: none;
                    &:hover{
                        background: $lighter-blue;
                        a{
                            background: transparent;
                            &:hover{
                                background: transparent;
                            }
                        }
                    }
                }
                &:hover{
                    background-color: none !important;
                    background: $lighter-blue;
                    @media(min-width: $screen-md-min){
                        transition: all 0.3s;
                        &>.sub-menu{
                            display: block;
                            z-index: 1000;
                            transition: all 0.3s;
                            transition-delay: 0s !important;
                            opacity: 1;
                            overflow: visible;
                            height: 60px;
                        }
                    }
                }
                .sub-menu{
                    @media(min-width: $screen-md-min){
                        height: 0;
                        transition: all 0.3s;
                        opacity: 0;
                        overflow: hidden;
                        display: block;
                        box-shadow: -100vw 100vw 0 100vw $lighter-blue;
                        clip: rect(0,100vw, 60px, -100vw);
                    }

                    display: none;
                    height: 60px;
                    transition-delay: 0.3s !important;
                    position: absolute;
                    right: 0;
                    top: 81px;
                    text-align: right;
                    background: $lighter-blue;
                    list-style-type: none;
                    li{
                        position: relative !important;
                        float: left;
                        a{
                            display: block;
                            font-size: 18px;
                            padding: 18px 30px;
                            text-transform: none;
                            color: $white;
                            &:hover{
                                background: transparent;
                                text-decoration: none;
                            }
                        }
                        &:hover{
                            background: transparent;
                            a{
                                color: $brand-primary;
                            }
                            /*background: rgba(6,70,108,0.1);*/
                        }
                    }
                }
            }
        }
    }
}

.cover{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    background: rgba(0,0,0,0.5);
    /*background: rgba(6,70,108,0.5);*/
}

header.banner{
    @media(max-width: $screen-xs-max){
        height: 70vh;
        min-height: 70vh;
        margin-top: 80px;
        position: relative;
    }
    //sd
    background: $brand-primary;
    min-height: 65vh;
    overflow: hidden;
    position: relative;
    #header-slider{
        .carousel-control{
            @media(max-width: $screen-xs-max){
                height: 70vh;
                font-size: 20px;
                width: 10%;
            }
            height: 65vh;
            font-size: 40px;
            vertical-align: middle;
            display: table;
            span.icon-arrow-left{
                display: table-cell;
                vertical-align: middle;
            }
            span.icon-arrow-right{
                display: table-cell;
                vertical-align: middle;
            }
        }
        .item{
            @media(max-width: $screen-xs-max){
                height: 70vh;

            }
            width: 100vw;
            height: 65vh;
            .center-box{
                @media(max-width: $screen-sm-max){
                    width: 60vw;
                    padding: 20px;
                }
                @media(max-width: $screen-xs-max){
                    padding: 0;
                    height: 70vh;
                    .btn{
                        padding: 10px 20px !important;
                        font-size: 16px !important;
                    }
                }
                width: 30vw;
                display: table;
                vertical-align: middle;
                margin: auto;
                color: $white;
                text-align: center;
                height: 65vh;
                .middle-align{
                    display: table-cell;
                    vertical-align: middle;
                }
                h2{
                    font-family: 'Roboto Slab', sans-serif;
                    text-transform: uppercase;
                    font-size: 64px;
                    margin-bottom: 20px;
                    @media(max-width: $screen-sm-max){
                        font-size: 28px;
                    }
                }
                p{
                    margin-bottom: 20px;
                }

                .btn{
                    border: 2px solid $white;
                    padding: 20px 30px;
                    color: $white;
                    display: table;
                    margin: 0 auto;
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: 600;
                    border-radius: 0;
                    -webkit-transition: background 0.5s, color 0.5s; /* Safari */
                    transition: background 0.5s, color 0.5s;
                    &:after{
                        content: ' >>';
                    }
                    &:hover{
                        background: $white;
                        color: $brand-primary;
                    }
                }
            }
        }
    }
}

.header-picture{
    min-height: 67vh;
    width: 100%;
    display: block;
}



.search-form{
    float: right;
    .search-field{
        background: none;
        border: 0;
        border-radius: 0;
        border-left: 2px solid $white;
        margin: 12px 0;
        color: $white;
        font-size: 18px;
        box-shadow: none;
        &::-webkit-input-placeholder {
            opacity: 1;
            color: $white;
        }
        &:-moz-placeholder { /* Firefox 18- */
            opacity: 1;
            color: $white;
        }
        &::-moz-placeholder {  /* Firefox 19+ */
            opacity: 1;
            color: $white;
        }
        &:-ms-input-placeholder {  
            opacity: 1;
            color: $white;
        }
    }
    .search-submit{
        display: block;
        width: 82px;
        border: none;
        border-left: 2px solid $white;
        margin: 12px 0;
        color: rgba(255,255,255,0.8);
        font-size: 18px;
        font-weight: 600;
        background: none;
        &:hover{
            color: $white;
        }
    }
}

button.hamburger-button{
    position: fixed;
    top: 0;
    right: 0;
    border: none;
    background: none;
    font-size: 24px;
    color: $white;
    padding: 26px 23px;
    .glyphicon-menu-hamburger{
        display: block;
    }
    .cls-icon{
        display: none;
    }
    &.active{
        padding: 10px 26px;
        font-size: 40px;
        .glyphicon-menu-hamburger{
            display: none;
        }
        .cls-icon{
            display: block;
        }
    }
    &:focus{
        outline: none;
    }
}