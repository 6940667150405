.highlighted-text{
    @media(max-width: $screen-xs-max){
        padding: 40px 0 20px 0;
        h2{
            margin: 0;
            padding: 0;
        }
        p{
            b{
                font-size: 18px !important;
            }
            margin: 20px 0 !important;
            font-size: 16px !important;
        }
    }
    padding: 70px 0;
    p{
        b{
            font-size: 24px;
        }
        margin: 30px 0;
        font-size: 20px;
    }
}
//sds
.home-box{
    @media(max-width: $screen-xs-max){
        padding: 40px 0 0 0;
        h2{
            font-size: 20px;
            margin: 20px 0 !important;
        }
    }
    padding: 60px 60px 10px 60px;
    .ikon{
        width: 80px;
        height: 80px;
        border-radius: 80px;
        border: 2px solid $white;
        margin: 0 auto;
        vertical-align: middle;
        &.ikon-idea{background: url('../images/icon-idea.png') no-repeat center;}
        &.ikon-tabla{background: url('../images/icon-tabla.png') no-repeat center;}
    }
    .icon-idea{
        line-height: 1;
        font-size: 80px;
    }
    .icon-signs{
        line-height: 1;
        font-size: 80px;
    }
    h2{
        margin: 40px 0;
    }
}
.button-control{
    @media(max-width: $screen-xs-max){
        padding: 10px 0 40px 0;
    }
    padding: 30px 0 60px 0;
    .btn{
        border: 2px solid $white;
        padding: 10px 30px;
        text-transform: uppercase;
        font-weight: 600;
        display: table;
        margin: 0 auto;
        border-radius: 0;
        color: $white;
        -webkit-transition: background 0.5s, color 0.5s; /* Safari */
        transition: background 0.5s, color 0.5s;
        &:after{
            content: ' >>';
        }
        &:hover{
            background: $white;
            color: $brand-primary;
        }
    }
}

.page-header{
    @media(max-width: $screen-xs-max){
        margin: 80px 0 0 0;
        padding: 20px 0 20px 0;
    }
    margin: 0;
    border: none;
    text-align: center;
    padding: 40px 0 20px 0;
    h1{
        margin: 0;
        font-size: 34px;
    }
}

.employees{
    @media(max-width: $screen-xs-max){
        div[class*=col-sm]{
            .media{
                margin-top: 0;
                padding-top: 20px;
                padding-bottom: 20px;
                position: relative;
                .media-body{
                    h4{
                        font-size: 20px;
                    }
                }
            }
            border-bottom: 2px dashed rgba(6,70,108,0.1);
            &:last-of-type{
                border-bottom: 0;    
            }
        }
    }
    padding-bottom: 100px;
    .director{
        .media{
            display: table;
            margin: 0 auto !important;
            padding-top: 50px;
            color: $brand-primary;
            img{
                position: relative;
                margin: 5px;
                border-radius: 100px;
                width: 150px;
                border: 4px solid #c3d2db;
                box-shadow: 0 0 0 5px #dfe7ec;
            }
            .media-left{
                padding-right: 20px;
            }
            .media-body{
                vertical-align: middle;
                font-size: 16px;
                width: inherit;
                h4{
                    font-size: 36px;
                }
            }
        }
    }
    .media{
        @media(max-width: $screen-xs-max){
            margin-top: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                display: block;
                border-bottom: 2px dashed rgba(6,70,108,0.1);
            }
            &:last-child{
                &:after{
                    border-bottom: 0;    
                }
            }
            img{
                margin: 20px auto;
            }
            .media-left{
                padding: 0;
                padding-right: 0 !important;
                display: table;
                margin: 0 auto;
            }
            .media-body{
                margin-top: 10px;
                text-align: center;
                width: 100%;
                display: block;
                float: none;
            }
        }
        padding-top: 50px;
        color: $brand-primary;
        &:hover{
            .media-left{
                &:after{
                    transition: all 0.3s;
                    box-shadow: 0 0 0 12px $brand-primary;
                }
            }
        }
        .media-left{
            padding-right: 20px;
            position: relative;
            &:after{
                transition: all 0.3s;
                border-radius: 200px;
                margin: 12px;
                content: "";
                position: absolute;
                box-shadow: none;
                display: block;
                width: 116px;
                top: 0;
                left: 0;
                bottom: 0;
            }
            img{
                margin: 5px;
                border-radius: 100px;
                width: 130px;
                border: 4px solid #c3d2db;
                box-shadow: 0 0 0 5px #dfe7ec;
                position: relative;
            }
        }
        .media-body{
            vertical-align: middle;
            font-size: 14px;
            h4{
                font-size: 30px;
            }
        }
    }
}


.grey-bar{
    line-height: 1.5;
    padding: 40px 0;
    background: #eff0f0;
    h4{
        text-transform: none;
        margin: 20px 0;
        font-weight: 600;
        font-size: 20px;
    }
    em{
        font-style: normal;
        display: block;
        border-left: 2px solid $brand-primary;
        padding: 20px 25px;
        text-align: justify;
        .director{
            .media{
                margin: 0;
                padding-top: 30px;
                color: $brand-primary;
                img{
                    margin: 5px;
                    border-radius: 100px;
                    width: 80px;
                    border: 3px solid #c3d2db;
                    box-shadow: 0 0 0 3px #dfe7ec;
                }
                .media-left{
                    padding-right: 10px;
                }
                .media-body{
                    vertical-align: middle;
                    font-size: 10px;
                    width: inherit;
                    h4{
                        margin: 0;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.grey-bar,.white-bar{
    ul{
        list-style-type: none;
        margin: 0 0 20px 20px;
        padding: 0;
        &>li{
            position: relative;
            ul{
                margin: 0;
                padding: 0;
                margin-bottom: 20px;
                margin-left: 10px;
                li{
                    margin: 6px 0;
                }
            }
        }
    }
    ol{
        padding: 0;
        margin: 0;
        margin-left: 40px;
        li{
            margin: 16px 0;
        }
    }
}

.standard{
    @media(max-width: $screen-sm-max){
        min-height: auto;
        padding-bottom: 30px !important;
    }
    padding-bottom: 60px;
    h4{
        text-transform: none;
        margin: 20px 0 40px 0;
        font-weight: 600;
        font-size: 20px;
    }
    p{
        margin: 0 0 20px 0;
    }
}

.separator{
    /*    @media(max-width: $screen-sm-max){
            display: none;
        }*/
    border-top: 12px solid #9aa8b1;
    padding: 40px;
    text-align: center;
    h4{
        color: $brand-primary;
    }
}

.graphicon-container{
    @media(max-width: $screen-sm-max){
        display: none !important;
    }
    overflow: hidden;
    height: 300px;
    width: 100%;
    position: relative;
    canvas{
        position: absolute;
        bottom: 0;
    }
}

.blue-bar{
    &.thin{
        padding: 20px 0;
    }
    padding: 50px 0;
    background: $brand-primary;
    color: $white;
    h3{
        margin: 0 0 40px 0;
    }
    .form-control{
        border: none;
        border-radius: 0;
        box-shadow: none;
        &:focus{
            outline: none;
        }
    }
    input.form-control, .btn.form-control{
        height: 40px;
        padding: 10px 12px;
    }
    .btn{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        font-family: $ptsans;
        &.btn-default{
            color: $brand-primary;
        }
        &.btn-primary,&.btn-primary:focus{
            color: $white;
            background: $brand-primary;
            height: 45px;
            border: 2px solid $white !important;
            &:hover{
                color: $brand-primary;
                background: $white;
            }
        }
    }

}

.white-bar{
    &.first{
        padding: 0 0 40px;
    }
    padding: 40px 0;

    h4{
        text-transform: none;
        margin: 20px 0;
        font-weight: 600;
        font-size: 20px;
    }
}

.mission-line{
    @media(max-width: $screen-sm-max){
        .mission-boxes{
            margin-top: 30px !important;
            width: 100% !important;
        }
        .mission-box{
            top: 0 !important;
            margin-bottom: 30px !important;
            &:before{
                content: '';
                border-top: 2px solid $brand-primary;
                display: block;
                position: absolute;
                top: 20px;
                left: -$grid-gutter-width;
                right: -$grid-gutter-width;
            }
            .text-box{
                margin-top: 10px !important;
            }
        }
        .blue-line{
            display: none;
        }
    }
    padding: 40px 0;

    .ikon-control{
        display: table;
        margin: 0 auto;
        .ikon-superman{
            display: block;
            width: 80px;
            height: 80px;
            background: url('../images/superman.png') no-repeat center;
            background-size: contain;
        }
        .icon-superman{
            color: $brand-primary;
            font-size: 80px;
            line-height: 1;
        }
    }
    .blue-line{
        background: $brand-primary;
        height: 2px;
        margin: 80px 0;
    }
    .mission-boxes{
        margin-top: 0;
    }
    .mission-box{
        top: -101px;
        position: relative;
        margin-bottom: -70px;
        .number-control{
            display: table;
            margin: 0 auto;
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 40px;
            background: $brand-primary;
            .number{
                color: $white;
                height: 40px;
                display: table-cell;
                text-align: center;
                vertical-align: middle;
            }
        }
        .text-box{
            text-align: center;
            margin-top: 40px;
        }
    }
}

.arguments{
    h2{
        margin-top: 0;
        margin-bottom: 80px;
    }
    .argument-box{
        .ikon-control{
            display: table;
            margin: 0 auto;
            .arg{
                display: block;
                width: 80px;
                height: 80px;
            }
            .icon{
                font-size: 80px;
                color: $brand-primary;
                line-height: 1;
            }
            .arg-gear{
                background: url('../images/ervek_melettunk_jovo.png') no-repeat center;
                background-size: contain;
            }
            .arg-lamp{
                background: url('../images/ervek_melettunk_kreativ.png') no-repeat center;
                background-size: contain;
            }
            .arg-people{
                background: url('../images/ervek_melettunk_egyutt.png') no-repeat center;
                background-size: contain;
            }
            .arg-family{
                background: url('../images/ervek_melettunk_munka_maganelet.png') no-repeat center;
                background-size: contain;
            }
        }
        .text-content{
            text-align: center;
            padding: 20px;
            h3{
                font-size: 20px;
                color: $brand-primary;
                text-transform: none;
            }
        }
    }
}

.careers{
    @media(max-width: $screen-sm-max){
        .attitude-line{
            padding: 20px 60px !important;
        }
        #carousel-careers{
            .carousel-indicators{
                width: 100%;
                li{
                    width: 30px !important;
                    margin: 0 10px !important;
                }
            }
        }
    }
    padding: 40px 0 60px 0;
    h2{
        padding: 0;
        margin: 0 0 40px 0;
    }
    h3{
        font-size: 20px;
        text-transform: none;
        color: $brand-primary;
    }
    .attitude-line{
        position: relative;
        background: #dfe7ec;
        color: $brand-primary;
        text-align: center;
        padding: 20px;
        margin: 20px 0;
        font-size: 18px;
        font-style: italic;
    }
    #carousel-careers{
        .carousel-control{
            @media(max-width: $screen-xs-max){
                width: inherit !important;
            }
            color: $brand-primary;
            width: 80px;
            span{
                font-size: 30px;
            }
            &.left{
                background: none;
            }
            &.right{
                background: none;
            }
            .glyphicon{
                top: 306px;
                font-size: 38px;
            }
        }
        .carousel-indicators{
            position: relative;
            display: table;
            margin: 0 auto;
            left: 0;
            bottom: 0;
            padding: 20px;
            li{
                background: #a3c8e0;
                width: 70px;
                height: 13px;
                border-radius: 0;
                box-shadow: none;
                border: none;
                margin: 0 20px;
                &.active{
                    margin: 0 20px;
                    background: $brand-primary;
                }
            }
        }
        .carousel-inner{
            .item{
                .media{
                    @media(max-width: $screen-xs-max){
                        margin-top: 0;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        position: relative;
                        &:after{
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            height: 1px;
                            display: block;
                        }
                        img{
                            margin: 20px auto;
                        }
                        .media-left{
                            padding: 0;
                            padding-right: 0 !important;
                            display: table;
                            margin: 0 auto;
                        }
                        .media-body{
                            margin-top: 10px;
                            text-align: center;
                            width: 100%;
                            display: block;
                            float: none;
                            h4{
                                font-size: 20px !important;
                            }
                        }
                    }
                    display: table;
                    margin: 0 auto !important;
                    padding-top: 50px;
                    color: $brand-primary;
                    img{
                        margin: 5px;
                        border-radius: 100px;
                        width: 150px;
                        border: 4px solid #c3d2db;
                        box-shadow: 0 0 0 5px #dfe7ec;
                    }
                    .media-left{
                        padding-right: 20px;
                    }
                    .media-body{
                        vertical-align: middle;
                        font-size: 16px;
                        width: inherit;
                        h4{
                            font-size: 36px;
                        }
                    }
                }
            }
        }
    }
}

.jobs{
    @media(max-width: $screen-sm-max){
        padding-top: 0;
    }
    padding: 65px 0;
    .panel{
        border: 0;
        box-shadow: none;
        .panel-heading{
            border: 0;
            background: none;
            border-radius: 0;
            background: $brand-primary;
            padding: 0;
            margin: 0;
            a{
                display: block;
                padding: 14px;
                text-decoration: none;
                h4{
                    font-weight: normal;
                    text-transform: none;
                    color: $white;
                    font-size: 18px;
                }
            }
            .ikon-control{
                float: right;
                position: relative;
                top: -5px;
                .position-status-ikon{
                    display: block;
                    width: 30px;
                    height: 30px;
                    background: url('../images/position_icon.png') no-repeat center;
                    background-color: transparent;
                    border-radius: 30px;
                    background-size: contain;
                    &.open{
                        background-color: #f99a1c;
                    }
                }
                .icon-idea{
                    display: block;
                    width: 30px;
                    height: 30px;
                    background-color: transparent;
                    border-radius: 30px;
                    line-height: 1;
                    font-size: 30px;
                    &:before{
                        margin-left: 0;
                    }
                    &.open{
                        background-color: #f99a1c;
                    }
                }
            }
        }
        .panel-collapse>.panel-body{
            border: 0;
            padding: 10px;
            h4{
                display: block;
                color: $brand-primary;
                border-top: 2px solid $lighter-blue;
                padding: 20px 0 10px 0;
                margin: 0;
            }
        }
        .panel-collapse{
            .panel{
                margin-top: 1px;
                .panel-heading{
                    background: #99b6c8;
                }
            }
        }
    }
}

.contact-sidebar-container{
    padding: 25px;
    border: 2px solid $brand-primary;

    h2{
        color: $brand-primary;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 2px solid $brand-primary;
    }
    h4{
        color: $brand-primary;
    }

    .media{
        display: table;
        color: $brand-primary;
        margin-top: 20px;
        img{
            margin: 5px;
            border-radius: 100px;
            width: 120px;
            border: 4px solid #c3d2db;
            box-shadow: 0 0 0 3px #dfe7ec;
        }
        .media-left{
            padding-right: 20px;
        }
        .media-body{
            vertical-align: middle;
            font-size: 18px;
            width: inherit;
            h4{
                font-size: 20px;
            }
            .btn-link{
                padding: 0;
                font-weight: 600;
                font-size: 16px;
                i.icon-email{
                    font-size: 20px;
                    vertical-align: middle;
                }
                &:hover{
                    color: $brand-primary;
                }
            }
        }
    }

    .legend{
        padding: 14px;
        color: $white;
        background: $brand-primary;
        .legend-box{
            margin-bottom: 14px;
            &:last-child{
                margin-bottom: 0;
            }
            .legend-title{
                h4{
                    float: left;
                    text-transform: none;
                    color: $white;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    .ikon-control{
                        display: inline;
                        vertical-align: middle;
                        float: left;
                        margin-right: 14px;
                        .position-status-ikon{
                            display: block;
                            width: 30px;
                            height: 30px;
                            background: url('../images/position_icon.png') no-repeat center;
                            background-color: transparent;
                            border-radius: 30px;
                            background-size: contain;
                            &.open{
                                background-color: #f99a1c;
                            }
                        }
                        .icon-idea{
                            display: block;
                            width: 30px;
                            height: 30px;
                            background-color: transparent;
                            border-radius: 30px;
                            line-height: 1;
                            font-size: 30px;
                            &:before{
                                margin-left: 0;
                            }
                            &.open{
                                background-color: #f99a1c;
                            }
                        }
                    }
                    .middle-align{
                        display: table-cell;
                        vertical-align: middle;
                        height: 100%;
                    }
                }
            }
            .legend-content{
                padding-top: 10px;
                display: block;
                clear: both;
            }
        }
    }
    .information-block{
        margin-top: 40px;
        margin-bottom: 40px;
        .block-title{
            .ikon-control{
                float: left;
                margin-right: 14px;
                .icon-info{
                    font-size: 50px;
                    line-height: 1;
                    color: $brand-primary;
                    &:before{
                        margin: 0;
                    }
                }
                .ikon-info{
                    display: block;
                    width: 50px;
                    height: 50px;
                    background: url('../images/icon-info.png') no-repeat center;
                    background-size: contain;
                }
            }
            h4{
                font-size: 24px;
            }
        }
        .block-content{
            font-size: 18px;
            color: $brand-primary;
        }
    }
}

.contact-box-row{
    @media(max-width: $screen-xs-max){
        margin-top: 70vh;
        box-sizing: border-box;
        .padding-control{
            margin: 0;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .contact-box{
        @media(max-width: $screen-sm-max){
            margin: 0;
            box-sizing: border-box;
        }
        z-index: 10000;
        background: $brand-primary;
        width: 100%;
        margin: 60px;
        color: $white;
        padding: 25px 25px 70px 25px;
        h2{
            margin-top: 0;
            margin-bottom: 18px;
            border-bottom: 2px solid $white;
            padding-bottom: 20px;
        }
        .company{
            font-weight: 600;
            text-transform: uppercase;
            font-size: 26px;
        }
        .address{
            padding: 6px;
            font-size: 20px;
            a{
                color: $white;
            }
            ul{
                padding-top: 10px;
                padding-left: 10px;
                padding-bottom: 10px;
                margin: 0;
                list-style-type: none;
                li{
                    padding: 6px;
                    font-size: 16px;
                    span{
                        font-weight: 300;
                        font-size: 28px;
                        margin-right: 6px;
                        vertical-align: middle;
                        display: inline;
                    }
                }
            }
        }
    }
}

.job-form{
    width: 100%;
    margin-top: 20px;
}

.job-share{
    .social-icons{
        display: inline;
        line-height: 1;
        vertical-align: middle;
        font-size: 26px;
        position: relative;
        top: -5px;
        float: right;
        .fa-linkedin-square{
            color: #0077B5;
        }
        .fa-facebook-square{
            color: #3A5795;
        }
        .fa-twitter-square{
            color: #5EA9DD;
        }
    }
}

.home-boxes{
    .col-sm-6{
        @media(max-width: $screen-xs-max){
            margin: 0 -15px;
            &:nth-child(2){
                background: rgba(0,0,0,0.2);
            }
        }
    }
}

.open-positions-content{
    min-height: 70vh;
    background: url('../images/nyitott_poziciok_abra.svg') no-repeat center bottom;
    background-size: 100% auto;
    //sd
}

#contact-map{
    @media(max-width: $screen-xs-max){
        height: 70vh;
        position: absolute;
        &:after,&:before{
            background: transparent;
            content: '';
            background: rgba(6,70,108, 0.2);
            display: block;
            position: absolute;
        }
        &:after{
            top: 0;
            left: 0;
            width: 100px;
            height: 70vh;
        }
        &:before{
            top: 0;
            right: 0;
            z-index: 1000;
            width: 100px;
            height: 70vh;
        }
    }
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    margin-bottom: 60px;
    width: 100%;
    border: none;
}

.counter{
    .big-number{
        font-size: 200px;
        line-height: 1;
    }
    .display-text{
        text-transform: uppercase;
    }
}

.certificate{
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    margin-bottom: $grid-gutter-width;
    .text-box{
        color: $white;
        position: absolute;
        display: block;
        vertical-align: middle;
        z-index: 1000;
        background: rgba(6,70,108, 0.8);
        left: 0;
        border: 60px solid rgba(255,255,255,0.9);
        top: 0;
        bottom: 0;
        right: 0;
        -webkit-transition: border 0.3s; /* Safari */
        transition: border 0.3s;
        .align{
            font-weight: 600;
            font-size: 22px;
            margin: 30px auto 0 auto;
            display: table;
            vertical-align: middle;
            span.icon-search{
                font-size: 40px;
                clear: both;
                display: block;
            }
        }
    }
    img{
        position: absolute;
        left: -50%;
        top: -50%;
        -ms-transform: rotate(30deg); /* IE 9 */
        -webkit-transform: rotate(30deg); /* Chrome, Safari, Opera */
        transform: rotate(30deg);
        -webkit-transition: transform 0.3s; /* Safari */
        transition: transform 0.3s;
    }
    &:hover{
        .text-box{
            border: 20px solid rgba(255,255,255,0.4);
            -webkit-transition: border 0.3s; /* Safari */
            transition: border 0.3s;
        }
        img{
            -ms-transform: rotate(25deg); /* IE 9 */
            -webkit-transform: rotate(25deg); /* Chrome, Safari, Opera */
            transform: rotate(25deg);
            -webkit-transition: transform 0.3s; /* Safari */
            transition: transform 0.3s;
        }
    }
}

#car-mobil, #truck-mobil{
    .carousel-control{
        &.left, &.right{
            color: $brand-primary;
            background: none !important;
            background-image: transparent;
            opacity: 1;
            .glyphicon{
                top: 35%;
            }
        }
    }
}

@media(max-width: $screen-sm-max){
    .contact-sidebar-container{
        .media{
            width: 100%;
            .media-left, .media-body{
                display: table;
                margin: 0 auto;
                text-align: center;
            }
        }
    }
}