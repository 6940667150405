.grey-bar, .white-bar{
    .btn-default{
        border: 2px solid $brand-primary;
        border-radius: 0;
        font-weight: 600;
        color: $brand-primary;
        background: none;
        &:hover{
            background: $brand-primary;
            color: $white;
        }
    }
}