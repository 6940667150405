@font-face {
  font-family: 'bemind-icons';
  src: url('../fonts/bemind-icons.eot?81153387');
  src: url('../fonts/bemind-icons.eot?81153387#iefix') format('embedded-opentype'),
       url('../fonts/bemind-icons.woff?81153387') format('woff'),
       url('../fonts/bemind-icons.ttf?81153387') format('truetype'),
       url('../fonts/bemind-icons.svg?81153387#bemind-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'bemind-icons';
    src: url('../font/bemind-icons.svg?81153387#bemind-icons') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "bemind-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-logo:before { content: '\e800'; } /* '' */
.icon-man:before { content: '\e801'; } /* '' */
.icon-people:before { content: '\e802'; } /* '' */
.icon-phone:before { content: '\e803'; } /* '' */
.icon-printer:before { content: '\e804'; } /* '' */
.icon-search:before { content: '\e805'; } /* '' */
.icon-signs:before { content: '\e806'; } /* '' */
.icon-superman:before { content: '\e807'; } /* '' */
.icon-arrow-left:before { content: '\e808'; } /* '' */
.icon-arrow-right:before { content: '\e809'; } /* '' */
.icon-doc:before { content: '\e80a'; } /* '' */
.icon-email:before { content: '\e80b'; } /* '' */
.icon-family:before { content: '\e80c'; } /* '' */
.icon-gearman:before { content: '\e80d'; } /* '' */
.icon-idea:before { content: '\e80e'; } /* '' */
.icon-info:before { content: '\e80f'; } /* '' */
.icon-lamp:before { content: '\e810'; } /* '' */
.icon-upload:before { content: '\e811'; } /* '' */