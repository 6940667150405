// Grid system

.content{
    position: relative;
    top: 0;
    @media(max-width: $screen-sm-max){
        top: 80px;
    }
    @media(max-width: $screen-xs-max){
        top: 0;
    }
}

.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}