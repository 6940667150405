// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #06466C;
$lighter-blue:          #99b6c8;
$white:                 #ffffff;

$ptsans: "PT Sans", sans-serif;
$roboto: 'Roboto Slab', serif;