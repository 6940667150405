body .gform_wrapper form .gform_body ul,
body .gform_wrapper form .gform_body ul li
{
    list-style-type: none !important;
    list-style-image: none !important;
    list-style: none !important;
    background-image: none !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important
}
body .gform_wrapper form .gform_body ul > li:before
{
    content: "";
}

@media(max-width: $screen-sm-max){
    .ginput-container, .gfield{
        clear: both !important;
        width: 100% !important;
    }
    .ginput-container{
        input{
            width: 100% !important;
            margin: 0 0 20px 0 !important;
        }
    }
}

.blue-bar{
    #gform_wrapper_1{
        @media(max-width: $screen-sm-max){
            max-width: 100%;
            .gf_left_half{
                width: 100% !important;
                padding-bottom: 12px !important;
                input{
                    width: 100% !important;
                }
            }
            .gf_right_half{
                width: 100% !important;
                .ginput_container_fileupload{
                    padding-left: 0;
                    width: 100% !important;
                    input{
                        width: 100% !important;
                    }
                }
            }
        }
        .gf_right_half{
            width: 51%;
        }
        .ginput_container{
            margin-top: 0 !important;
            label{
                display: none;
            }
            textarea{
                color: #333;
                border: none;
                padding: 12px;
                margin-top: 12px;
                width: 100% !important;
                height: 200px !important;
                &:focus{
                    outline: none;
                }
            }
            input{
                color: #333;
                border: none;
                width: 97%;
                padding: 12px;
                margin: 0 !important;
                &:focus{
                    outline: none;
                }
            }
            input[type='file']{
                padding: 6px;
                opacity: 0;
                display: none;
                position: absolute;
                top: 0;
                width: 100% !important;
                &:focus{
                    outline: none;
                }
            }
            #field_1_2{
                width: 51%;
            }
        }
        .gform_button{
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            width: 100%;
            position: relative;
            font-family: $ptsans;
            color: $brand-primary;
            &,&:focus{
                color: $white;
                background: $brand-primary;
                height: 45px;
                border: 2px solid $white !important;
                &:hover{
                    color: $brand-primary;
                    background: $white;
                }
            }

        }
        .gform_footer{
            padding: 0 !important;
            margin: 12px 0 0 0 !important;
        }
        .ginput_container_fileupload{
            padding-left: 10px;
        }
    }
}

.job-details{
    display: block;
    clear: both;
    .btn-default{
        margin: 10px 0 30px 0;
        padding: 10px 20px;
        background: $white !important;
        color: $brand-primary !important;
        font-weight: 600;
        border: 2px solid $brand-primary;
        min-width: 200px;
        border-radius: 0 !important;
        font-size: 18px;
        font-size: 16px;
        text-transform: uppercase;
        &:hover{
            background: $brand-primary !important;
            color: $white !important;
        }
        @media(max-width: $screen-sm-max){
            min-width: 100%;
        }
    }
}

.job-form{
    clear: both;
    display: table;
    padding-bottom: 20px;
    .gform_wrapper{
        margin: 0;
        padding: 0;
        max-width: 100% !important;
        label.gfield_label + div.ginput_container{
            margin: 0;
            padding: 0;
        }
        .top_label li.gfield.gf_right_half{
            float: right;
            width: 50%;
        }
    }
    input{
        margin-top: 0 !important;
        background: #e8e8e8;
        border: 2px solid #e8e8e8;
        padding: 10px !important;
        &.gform_button{
            padding: 10px 20px;
            background: $white !important;
            color: $brand-primary !important;
            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
            border: 2px solid $brand-primary;
            min-width: 29.9999%;
            &:hover{
                background: $brand-primary !important;
                color: $white !important;
            }
        }
        &::-webkit-input-placeholder {
            opacity: 1;
            color: #333;
        }

        &:-moz-placeholder { /* Firefox 18- */
            opacity: 1;
            color: #333;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
            opacity: 1;
            color: #333; 
        }

        &:-ms-input-placeholder {  
            opacity: 1;
            color: #333; 
        }
        &:focus{
            outline: none;
        }
        @media(max-width: $screen-sm-max){
            width: 100% !important;
        }
    }
    textarea{
        width: 100% !important;
        background: #e8e8e8;
        border: none;
        margin-top: 20px !important;
        padding: 10px !important;
        &:focus{
            outline: none;
        }
        &::-webkit-input-placeholder {
            opacity: 1;
            color: #333;
        }

        &:-moz-placeholder { /* Firefox 18- */
            opacity: 1;
            color: #333;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
            opacity: 1;
            color: #333; 
        }

        &:-ms-input-placeholder {  
            opacity: 1;
            color: #333; 
        }
    }
    .gform_footer{
        margin: 0;
        padding: 0 !important;
        input{
            float: right;
            @media(max-width: $screen-sm-max){
                width: 100%;
            }
        }
    }
}

.job-send-form{
    button.btn-default{
        padding: 10px;
        width: 100%;
        background: $white !important;
        color: $brand-primary !important;
        font-weight: 600;
        border: 2px solid $brand-primary;
        min-width: 200px;
        border-radius: 0;
        font-size: 16px;
        text-transform: uppercase;
        &:hover{
            background: $brand-primary !important;
            color: $white !important;
        }
    }
    input[type=text]{
        width: 100% !important;
        padding: 10px;
        background: #e8e8e8;
        border: 2px solid #e8e8e8 !important;
        border-radius: 0;
        box-shadow: none;
        padding: 10px !important;
        &::-webkit-input-placeholder {
            opacity: 1;
            color: #333;
        }

        &:-moz-placeholder { /* Firefox 18- */
            opacity: 1;
            color: #333;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
            opacity: 1;
            color: #333; 
        }

        &:-ms-input-placeholder {  
            opacity: 1;
            color: #333; 
        }
        &:focus{
            outline: none !important;
            box-shadow: none;
        }
    }

    .gform_body{
        float: left !important;
        width: 69.9999% !important;
        margin: 0 !important;
        padding: 0 !important;
        @media(max-width: $screen-sm-max){
            width: 100% !important;
        }
    }
    .gform_wrapper{
        margin: 0;
        li.hidden_label{
            input{
                margin: 0;
            }
        }
    }
    label{
        display: none;
    }
    .ginput_container{
        padding: 16px;
        margin-top: 0 !important;
    }
    .gform_footer{
        margin: 0 !important;
        padding: 16px 0 !important;
        clear: none !important;
        float: right !important;
        width: 29.9999% !important;
        input[type="submit"].gform_button.button{
            padding: 10px;
            width: 100%;
            background: $white !important;
            color: $brand-primary !important;
            font-weight: 600;
            border: 2px solid $brand-primary;
            min-width: 100%;
            border-radius: 0;
            font-size: 16px;
            text-transform: uppercase;
            &:hover{
                background: $brand-primary !important;
                color: $white !important;
            }
        }
        @media(max-width: $screen-sm-max){
            width: 100% !important;
        }
    }
}

.blue-bar{
    .upload_click{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        width: 100%;
        color: $brand-primary;
        display: block;
        text-align: center;
        text-decoration: none;
        padding: 10px;
        &,&:focus{
            color: $brand-primary;
            background: $white;
            border: 2px solid $white;
            text-decoration: none;
            &:hover{
                opacity: 0.9;
                color: $brand-primary;
                background: $white;
            }
        }
    }
}

.collapse{
    .upload_click{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        width: 100%;
        color: $brand-primary;
        border: 2px solid $brand-primary;
        text-align: center;
        text-decoration: none;
        padding: 10px;
        &,&:focus{
            color: $brand-primary;
            text-decoration: none;
            &:hover{
                text-decoration: none;
                color: $white;
                background: $brand-primary;
            }
        }
        @media(max-width: $screen-sm-max){
            margin-top: 20px !important;
        }
    }    
}

input[type='file']{
    display: none;
}

.contact-box{
    label.gfield_label{
        display: none !important;
    }
    .gform_wrapper{
        margin: 0 !important;
        max-width: 100% !important;
    }
    input[type='text'],textarea{
        width: 100% !important;
        border: none;
        color: #333333;
        margin-bottom: 10px;
        padding: 10px !important;
        &:focus{
            outline: none;
        }
        &::-webkit-input-placeholder {
            opacity: 1;
            color: #333;
        }

        &:-moz-placeholder { /* Firefox 18- */
            opacity: 1;
            color: #333;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
            opacity: 1;
            color: #333; 
        }

        &:-ms-input-placeholder {  
            opacity: 1;
            color: #333; 
        }
    }
    .gform_footer{
        margin: 0 !important;
        padding: 0 !important;
        input[type="submit"].gform_button.button{
            padding: 10px;
            width: 100%;
            background: $brand-primary !important;
            color: $white !important;
            font-weight: 600;
            border: 2px solid $white;
            min-width: 100%;
            border-radius: 0;
            font-size: 16px;
            text-transform: uppercase;
            &:hover{
                background: $white !important;
                color: $brand-primary !important;
            }
        }
    }
}

.ginput_preview{
    display: none;
}

.gfield_error{
    .ginput_container{
        input{
            background: none;
            border-color: #d64f5a !important;
        }
    }
}

.gform_wrapper{
    .validation_message{
        display: none !important;
    }
    .validation_error{
        display: none !important;
    }
}