body{
    font-family: $ptsans;
    font-size: 16px;
    color: #4c4c4c;
}

h1,h2,h3,h4,h5,h6{
    text-transform: uppercase;
    font-weight: 600;
}

.blue-bg{
    background: $brand-primary;
    color: $white;
}

.sub-menu{
    display: none;
}

.breadcrumb>li+li:before{
    content: '/\00a0';
    padding: 0 2px 0 4px;
    color: #4b4b4b;
}

.breadcrumb{
    color: #4b4b4b;
    background-color: transparent;
    display: table;
    padding: 0;
    text-transform: uppercase;
    font-size: 16px;
    margin: 10px auto 12px auto;
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

p{
    a{
        color: $brand-primary;
        &:hover{
            color: $brand-primary;
        }
    }
}

ul.pagination{
    display: table !important;
    margin: 0 auto !important;
    li{
        font-weight: 400;
        a{
            color: $lighter-blue;
            border-top: 2px solid $lighter-blue;
            border-bottom: 2px solid $lighter-blue;
            border-left: 2px solid $lighter-blue;
            &:hover{
                background: $lighter-blue;
                color: $white;
                border-color: $lighter-blue;
            }
        }
        span{
            color: $lighter-blue;
            &:hover{
                background: none;
            }
        }
        &:first-child{
            span{
                color: $lighter-blue;
                border-radius: 0;
                border-top: 2px dashed $lighter-blue;
                border-bottom: 2px dashed $lighter-blue;
                border-left: 2px dashed $lighter-blue;
            }
            a{
                border-radius: 0;
                border-top: 2px solid $lighter-blue;
                border-bottom: 2px solid $lighter-blue;
                border-left: 2px solid $lighter-blue;
            }
        }
        &:last-child{
            span{
                color: $lighter-blue;
                border-radius: 0;
                border-top: 2px dashed $lighter-blue;
                border-bottom: 2px dashed $lighter-blue;
                border-right: 2px dashed $lighter-blue;
                border-left: 2px solid $lighter-blue;
            }
            a{
                border-radius: 0;
                border-top: 2px solid $lighter-blue;
                border-bottom: 2px solid $lighter-blue;
                border-right: 2px solid $lighter-blue;
                border-left: 2px solid $lighter-blue;
            }
        }
    }
}

.modal{
    z-index: 1000000;
}
.certifications{
    .modal{
        .modal-dialog{
            .modal-content{
                border-radius: 0;
                border: 0;
                box-shadow: 0 0 20px rgba(0,0,0,0.2);
                .modal-header{
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10000;
                    padding: 0;
                    border-bottom: 0;
                    button.close{
                        opacity: 1;
                        position: relative;
                        width: 40px;
                        height: 40px;
                        margin-top: 0;
                        background: $brand-primary;
                        display: block;
                        .closer-one{
                            width: 1px;
                            height: 30px;
                            position: absolute;
                            top: 5px;
                            left: 20px;
                            background: $white;
                            display: block;
                            -ms-transform: rotate(45deg); /* IE 9 */
                            -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                            transform: rotate(45deg);
                        }
                        .closer-two{
                            position: absolute;
                            height: 1px;
                            width: 30px;
                            top: 20px;
                            left: 5px;
                            background: $white;
                            display: block;
                            -ms-transform: rotate(45deg); /* IE 9 */
                            -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            img{
                height: 100% !important;
                display: table !important;
                //sdsd
            }
        }
    }
}

.hr-modal{
    .modal-dialog{
        width: 400px;
        margin: 10px auto;
    }
    .modal-content{
        border-radius: none;
        box-shadow: none;
        border: none;
        border-radius: 0;
        background: $brand-primary;
        .modal-footer{
            border-top: 0;
        }
        .modal-body{
            padding: 25px;
            .caption{
                padding: 6px 0;
                font-size: 18px;
                color: $white;
            }
            .media{
                padding: 0;
                margin: 0;
                color: $white;
                display: block;
                img{
                    margin: 5px;
                    border-radius: 100px;
                    width: 70px;
                    border: 4px solid #c3d2db;
                    box-shadow: 0 0 0 5px #dfe7ec;
                }
                .media-left{
                    padding: 0;
                    float: none;
                    margin: 0 auto;
                    display: table !important;
                }
                .media-body{
                    padding: 10px 0 20px 0;
                    display: block;
                    clear: both;
                    vertical-align: middle;
                    font-size: 14px;
                    h4{
                        font-size: 18px;
                        text-transform: none;
                        color: $white;
                    }
                }
            }
        }
        .modal-header{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10000;
            padding: 25px;
            border-bottom: 0;
            button.close{
                opacity: 1;
                position: relative;
                width: 30px;
                height: 30px;
                display: block;
                .closer-one{
                    width: 1px;
                    height: 30px;
                    position: absolute;
                    top: 0;
                    left: 15px;
                    background: $white;
                    display: block;
                    -ms-transform: rotate(45deg); /* IE 9 */
                    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                    transform: rotate(45deg);
                }
                .closer-two{
                    position: absolute;
                    height: 1px;
                    width: 30px;
                    top: 15px;
                    left: 0;
                    background: $white;
                    display: block;
                    -ms-transform: rotate(45deg); /* IE 9 */
                    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                    transform: rotate(45deg);
                }
            }
        }
    }
}

.alert{
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
}

#confirm-modal{
    .modal-content{
        border-radius: 0;
        box-shadow: none;
        border: none;
        .modal-body{
            padding: 25px;
        }
        .modal-header{
            padding: 25px;
            border: 0;
            button.close{
                opacity: 1;
                position: relative;
                width: 30px;
                height: 30px;
                display: block;
                .closer-one{
                    width: 1px;
                    height: 30px;
                    position: absolute;
                    top: 0;
                    left: 15px;
                    background: $brand-primary;
                    display: block;
                    -ms-transform: rotate(45deg); /* IE 9 */
                    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                    transform: rotate(45deg);
                }
                .closer-two{
                    position: absolute;
                    height: 1px;
                    width: 30px;
                    top: 15px;
                    left: 0;
                    background: $brand-primary;
                    display: block;
                    -ms-transform: rotate(45deg); /* IE 9 */
                    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                    transform: rotate(45deg);
                }
            }
        }
    }
}